/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Loading from "./component/element/Loading";
import Header from "./component/layout/Header";
import Gov from "./component/element/Governance";
import Footer from "./component/layout/Footer";
import {
  FaTwitter,
  FaDiscord,
  FaReddit,
  FaGithub,
  FaFacebook,
  FaInstagram,
  FaMediumM,
  FaTelegramPlane,
} from "react-icons/fa";
import ReactGA from "react-ga";
ReactGA.initialize("UA-229541198-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <>
      {/* <Loading /> */}
      <Header />
      <div className='container-fluid bgs'>
        <Gov />
      </div>
      <div className='fixed-icons'>
        <a
          href='https://medium.com/@Aquatankio'
          target='_blank'
          rel='noopener noreferrer'>
          <FaMediumM size={25} />
        </a>
        <a href='https://twitter.com/Aquatankio'>
          <FaTwitter size={25} className='mt-3' />
        </a>
        <a href='https://t.me/aquatankio'>
          <FaTelegramPlane size={25} className='mt-3' />
        </a>
        <a href='https://discord.gg/zAjSvcXTug'>
          <FaDiscord size={25} className='mt-3' />
        </a>
        <a href='https://www.reddit.com/user/Aquatankio'>
          <FaReddit size={25} className='mt-3' />
        </a>
        <a href='https://github.com/Aquatankio'>
          <FaGithub size={25} className='mt-3' />
        </a>
        <a href='https://www.instagram.com/aquatankio/'>
          <FaInstagram size={25} className='mt-3' />
        </a>
        <a href='https://www.facebook.com/Aquatankio'>
          <FaFacebook size={25} className='mt-3' />
        </a>
      </div>
      <Footer />
    </>
  );
}

export default App;
