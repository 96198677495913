import React, { useEffect } from "react";
import { useState } from 'react'
import GovernanceHeader from './header'
import axios from 'axios'



export default function Gov() {
    
    const [proposals, setProposals] = useState();
 

    useEffect(() => {
        axios({
            method: 'post',
            url: 'https://hub.snapshot.org/graphql',
            data: {
                query: `
                query Proposals {
                    proposals(
                      first: 20,
                      skip: 0,
                      where: {
                        space_in: ["aquatank.eth"],
                        state: "closed"
                      },
                      orderBy: "created",
                      orderDirection: desc
                    ) {
                      id
                      title
                      body
                      choices
                      start
                      end
                      snapshot
                      state
                      author
                      space {
                        id
                        name
                      }
                    }
                  }`
            }
        }).then(res => {
            setProposals(res.data.data.proposals)
        }, (error) => {

        });
    }, [])
 
    return (
        <React.Fragment>
            <div className="pt-5"></div>
            <GovernanceHeader />
            <div className="container proposals mb-5 pb-5 mt-5" lang="">
                <div class="card ">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item font-weight-bolder fs-24 p-3 pl-4">Recent Proposals</li>
                        {
                            proposals ?
                                <React.Fragment>
                                
                                    {Object.keys(proposals).length === 0 &&
                                        <React.Fragment>
                                            <div className="p-5 m-auto text-center">
                                                <h5>Oops, we can't find any proposal.</h5>
                                            </div>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <p className="text-center mt-4 mb-4">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div><br />
                                    Please wait  , <br /> only for you.
                                </p>
                        }
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}