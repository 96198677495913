/** @format */

import React from "react";

export default function Footer() {
  return (
    <div className='container-fluid footer pt-3'>
      <div className='row'>
        <div className='col-md text-center'>
          <img src={"/logo.png"} alt='' width={250} />
          <p>
            {" "}
            Mint - Stake NFTs & Enjoy Lifetime Reward - contribute to the
            environment
          </p>
          {/* <ul className='list-unstyled d-flex justify-content-center list-items flex-wrap'>
            <li>
              <a href='#'>Home</a>
            </li>
            <li>
              <a
                href='https://aqua-tank.gitbook.io'
                target='_blank'>
                Document
              </a>
            </li>
            <li>
              <a href='https://mint.aquatank.xyz/' target='_blank'>
                Mint NFTs
              </a>
            </li>
            <li>
              <a  href='https://app.aquatank.xyz/' target='_blank'>Dashboard</a>
            </li>
            <li>
              <a href='https://market.aquatank.xyz/' target='_blank'>
                Marketplace
              </a>
            </li>
            <li>
              <a href='https://vote.aquatank.xyz/' target='_blank'>
                Governance
              </a>
            </li>
            <li>
              <a href='https://stake.aquatank.xyz/' target='_blank'>
                Stake
              </a>
            </li>
          </ul> */}
          {/* <div className='footer-bottom'> 
                    <a href="/">Download & Play</a>
                    <img src={process.env.REACT_APP_S3_URL+"/assets/footer-1.svg"} alt="" />
                    <img src={process.env.REACT_APP_S3_URL+"/assets/footer-2.svg"} alt="" className='mx-3' />
                </div> */}
          <p className='mt-3'>AquaTank 2024 © All right reserved</p>
        </div>
      </div>
    </div>
  );
}
